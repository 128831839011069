import { toast  } from 'react-toastify';
import * as GENERAL from './general'
const axios = require("axios");

export async function getDashboardInfo() {
  let url=process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX +'/ml-web-dashboard'
    return new Promise((resolve, reject) => {
    axios
      .get(url,{
        headers: GENERAL.returnHeader()
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        console.log(error)
      })
  });
}

export async function getBannerList() {
  let url=process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX +'/ml-marketing-banner'
    return new Promise((resolve, reject) => {
    axios
      .get(url,{
        headers:GENERAL.returnHeader()
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        console.log(error)
      })
  });
}

export async function globalSearch(params) {
  
  let url=process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX +'/ml-dashboard-search?'
  if(params.search)
    url+="&search="+params.search
  if(params.sort_by)  
    url+="&sort_by="+params.sort_by
  if(params.sort_type)
    url+="&sort_type="+params.sort_type
    return new Promise((resolve, reject) => {
    axios
      .post(url,{
        headers: GENERAL.returnHeader()
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        console.log(error)
      })
  });
}

export async function getStateList() {
  let url=process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX +'/ml-states?country_id=1'
    return new Promise((resolve, reject) => {
    axios
      .get(url,{
        headers: GENERAL.returnHeader()
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        console.log(error)
      })
  });
}

export async function getCityList(id) {
  let url=process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX +'/ml-cities'
    return new Promise((resolve, reject) => {
    axios
      .get(url,{
        headers: GENERAL.returnHeader()
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        console.log(error)
      })
  });
}

export async function getAccessToken() {
    let tokenData = await localStorage.getItem("MLtoken");
    if (tokenData) {
      return tokenData;
    } else {
      return null;
    }
}

export async function submitContactForm(data) {
  let url=process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX +'/ml-contact-form'
    return new Promise((resolve, reject) => {
    axios
      .post(url,data,{
        headers: GENERAL.returnHeader()
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        console.log(error)
      })
  });
}

export function returnHeader() {
  let tokenData = localStorage.getItem("MLtoken");
  if (tokenData) {
    return { Accept: "application/json",Authorization: 'Bearer '+ tokenData};
  } else {
    return { Accept: "application/json"};
  }
}

export function getTypeID(type) {
  if (type==='centre') {
    return 100
  }else if(type==='class')
    return 200
  else if(type==='ebook')
    return 300
  else if(type==='video')
    return 400
}

export function logout(expired){
    localStorage.removeItem("MLtoken");
    localStorage.removeItem("MLuser");
    if(expired){
      toast.error("Session expired. Please login again", { autoClose: 2000,position:'top-center' });
    }
}

