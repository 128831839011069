import * as GENERAL from "./general";
const axios = require("axios");

export async function getProfile(id) {
	let url =
		process.env.REACT_APP_API_URL +
		process.env.REACT_APP_API_PREFIX +
		"/ml-user-profile/" +
		id;
	return new Promise((resolve, reject) => {
		axios
			.get(url, {
				headers: GENERAL.returnHeader(),
			})
			.then((response) => {
				const { data } = response;
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
					if (error.response.data) {
						if (error.response.data.status === "unauthorized") {
							GENERAL.logout(true);
							reject("expired");
						} else reject(error.response.data);
					}
				}
			});
	});
}

export async function updateProfile(data) {
	let id = await localStorage.getItem("MLuser");
	let url =
		process.env.REACT_APP_API_URL +
		process.env.REACT_APP_API_PREFIX +
		"/ml-user-profile/" +
		id;
	return new Promise((resolve, reject) => {
		axios
			.post(url, data, {
				headers: GENERAL.returnHeader(),
			})
			.then((response) => {
				const { data } = response;
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
					if (error.response.data.status === "unauthorized") {
						GENERAL.logout(true);
						reject("expired");
					} else reject(error.response.data);
				}
			});
	});
}
export async function changeLanguage(language, id) {
	const formData = new FormData();

	const headers = GENERAL.returnHeader();
	formData.append("language", language);
	return new Promise((resolve, reject) => {
		axios
			.post(
				process.env.REACT_APP_API_URL +
					process.env.REACT_APP_API_PREFIX +
					"/ml-language/" +
					id +
					"?_method=PUT",
				formData,
				{
					headers,
					timeout: 15000,
				}
			)
			.then((response) => {
				const { data } = response;
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
					if (error.response.data.status === "unauthorized") {
						GENERAL.logout(true);
						reject("expired");
					} else reject(error.response.data);
				}
			});
	});
}

export async function changePassword(data) {
	let url =
		process.env.REACT_APP_API_URL +
		process.env.REACT_APP_API_PREFIX +
		"/profile/update-password";
	return new Promise((resolve, reject) => {
		axios
			.post(url, data, {
				headers: GENERAL.returnHeader(),
			})
			.then((response) => {
				const { data } = response;
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
					if (error.response.data.status === "unauthorized") {
						GENERAL.logout(true);
						reject("expired");
					} else reject(error.response.data);
				}
			});
	});
}

export async function getMyHistoryList(params) {
	let url =
		process.env.REACT_APP_API_URL +
		process.env.REACT_APP_API_PREFIX +
		"/ml-my-history?";
	if (params.per_page) url += "&per_page=" + params.per_page;
	if (params.page) url += "&page=" + params.page;
	if (params.type) url += "&type=" + params.type;
	return new Promise((resolve, reject) => {
		axios
			.get(url, {
				headers: GENERAL.returnHeader(),
			})
			.then((response) => {
				const { data } = response;
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
					if (error.response.data) {
						if (error.response.data.status === "unauthorized") {
							GENERAL.logout(true);
							reject("expired");
						} else reject(error.response.data);
					}
				}
			});
	});
}

export async function getHistory(id) {
	let url =
		process.env.REACT_APP_API_URL +
		process.env.REACT_APP_API_PREFIX +
		"/ml-my-history/" +
		id;
	return new Promise((resolve, reject) => {
		axios
			.get(url, {
				headers: GENERAL.returnHeader(),
			})
			.then((response) => {
				const { data } = response;
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
					if (error.response.data) {
						if (error.response.data.status === "unauthorized") {
							GENERAL.logout(true);
							reject("expired");
						} else reject(error.response.data);
					}
				}
			});
	});
}

export async function deleteHistory(id) {
	let url =
		process.env.REACT_APP_API_URL +
		process.env.REACT_APP_API_PREFIX +
		"/ml-my-history/" +
		id;
	return new Promise((resolve, reject) => {
		axios
			.delete(url, {
				headers: GENERAL.returnHeader(),
			})
			.then((response) => {
				const { data } = response;
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
					if (error.response.data) {
						if (error.response.data.status === "unauthorized") {
							GENERAL.logout(true);
							reject("expired");
						} else reject(error.response.data);
					}
				}
			});
	});
}
