import app from "firebase/app";
import React from "react";
import Axios from "axios";
const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
export default class Firebase {
	constructor() {
		if (!app.apps.length) {
			app.initializeApp(firebaseConfig);
		}
	}
}

const FirebaseContext = React.createContext(null);
const withFirebase = (Component) => (props) => (
	<FirebaseContext.Consumer>
		{(firebase) => <Component {...props} firebase={firebase} />}
	</FirebaseContext.Consumer>
);

const createShortLink = (targetUrl, title, description, imageUrl) => {
	const firebaseURL = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${firebaseConfig.apiKey}`;
	const data = {
		dynamicLinkInfo: {
			link: targetUrl,
			domainUriPrefix: process.env.REACT_APP_FIREBASE_DOMAIN,
			navigationInfo: {
				enableForcedRedirect: false, // ios only: if true means skip app preview page
			},
			androidInfo: {
				androidPackageName: "com.milearning",
				androidMinPackageVersionCode: "15",
				androidFallbackLink: targetUrl,
			},
			iosInfo: {
				iosBundleId: "com.antinternet.milearning",
				iosAppStoreId: "1512927836",
				iosFallbackLink: targetUrl,
				iosIpadFallbackLink: targetUrl,
			},
			socialMetaTagInfo: {
				socialTitle: title,
				socialDescription: description,
				socialImageLink: imageUrl,
			},
		},
		suffix: {
			option: "UNGUESSABLE",
		},
	};
	return new Promise((resolve, reject) => {
		Axios.post(firebaseURL, data, {
			headers: { Accept: "application/json" },
		})
			.then((response) => {
				const { data } = response;
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
					reject(error.response.data);
				}
			});
	});
};
export { FirebaseContext, withFirebase, createShortLink };
