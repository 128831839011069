import * as GENERAL from "./general";
const axios = require("axios");

export async function getCategories(params) {
	let url =
		process.env.REACT_APP_API_URL +
		process.env.REACT_APP_API_PREFIX +
		"/ml-center-category?";
	if (params.per_page) url += "&per_page=" + params.per_page;
	if (params.page) url += "&page=" + params.page;
	if (params.is_feature) url += "&is_feature=" + params.is_feature;
	if (params.sort_by) url += "&sort_by=" + params.sort_by;
	if (params.sort_type) url += "&sort_type=" + params.sort_type;
	return new Promise((resolve, reject) => {
		axios
			.get(url, {
				headers: GENERAL.returnHeader(),
			})
			.then((response) => {
				const { data } = response;
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
					if (error.response.data) {
						reject(error.response.data);
					}
				}
			});
	});
}

export async function getCentreDetails(id) {
	let url =
		process.env.REACT_APP_API_URL +
		process.env.REACT_APP_API_PREFIX +
		"/ml-center/" +
		id;
	return new Promise((resolve, reject) => {
		axios
			.get(url, {
				headers: GENERAL.returnHeader(),
			})
			.then((response) => {
				const { data } = response;
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
					if (error.response.data) {
						reject(error.response.data);
					}
				}
			});
	});
}

export async function getCentreList(params) {
	let url =
		process.env.REACT_APP_API_URL +
		process.env.REACT_APP_API_PREFIX +
		"/ml-center?";
	if (params.per_page) url += "&per_page=" + params.per_page;
	if (params.page) url += "&page=" + params.page;
	if (params.states) url += "&states=" + params.states;
	if (params.cities) url += "&cities=" + params.cities;
	if (params.categories) url += "&categories=" + params.categories;
	if (params.search) url += "&search=" + params.search;
	if (params.sort_by) url += "&sort_by=" + params.sort_by;
	if (params.sort_type) url += "&sort_type=" + params.sort_type;
	return new Promise((resolve, reject) => {
		axios
			.get(url, {
				headers: GENERAL.returnHeader(),
			})
			.then((response) => {
				const { data } = response;
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
					if (error.response.data) {
						reject(error.response.data);
					}
				}
			});
	});
}

export async function getMyCentres(params) {
	let url =
		process.env.REACT_APP_API_URL +
		process.env.REACT_APP_API_PREFIX +
		"/ml-my-center?status=1,2";
	if (params.per_page) url += "&per_page=" + params.per_page;
	if (params.page) url += "&page=" + params.page;

	return new Promise((resolve, reject) => {
		axios
			.get(url, {
				headers: GENERAL.returnHeader(),
			})
			.then((response) => {
				const { data } = response;
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
					if (error.response.data) {
						reject(error.response.data);
					}
				}
			});
	});
}

export async function createCentre(data) {
	let url =
		process.env.REACT_APP_API_URL +
		process.env.REACT_APP_API_PREFIX +
		"/ml-center";
	return new Promise((resolve, reject) => {
		axios
			.post(url, data, {
				headers: GENERAL.returnHeader(),
			})
			.then((response) => {
				const { data } = response;
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
					if (error.response.data.status === "unauthorized") {
						GENERAL.logout(true);
						reject("expired");
					} else reject(error.response.data);
				}
			});
	});
}

export async function updateCentre(data, id) {
	let accessToken = await GENERAL.getAccessToken();
	let url =
		process.env.REACT_APP_API_URL +
		process.env.REACT_APP_API_PREFIX +
		"/ml-center/" +
		id +
		"?_method=PUT";
	return new Promise((resolve, reject) => {
		axios
			.post(url, data, {
				headers: GENERAL.returnHeader(),
			})
			.then((response) => {
				const { data } = response;
				resolve(data);
			})
			.catch((error) => {
				if (error.response) {
					if (error.response.data.status === "unauthorized") {
						GENERAL.logout(true);
						reject("expired");
					} else reject(error.response.data);
				}
			});
	});
}
