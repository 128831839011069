import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import {
	setTranslations,
	setDefaultLanguage,
	setLanguage,
} from "react-multi-lang";
import en from "./translations/en.json";
import zh from "./translations/zh.json";
import ms from "./translations/ms.json";
import * as USER from "./services/user";
require("dotenv").config();

const app = (
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>
);

setTranslations({ en, zh, ms });
setDefaultLanguage("en");
async function getUserLanguage() {
	let id = await localStorage.getItem("MLuser");
	if (id !== null) {
		USER.getProfile(id).then((res) => {
			console.log(res.data.language);
			setLanguage(res.data.language);
		});
	}
	ReactDOM.render(app, document.getElementById("root"));
	serviceWorker.unregister();
}
getUserLanguage();
