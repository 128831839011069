import React from "react";

// Root Include
const Root = React.lazy(() => import("./pages/Pages/User/Login"));

//Special
const PageComingSoon = React.lazy(() =>
	import("./pages/Pages/Special/PageComingSoon")
);
const PageComingSoon2 = React.lazy(() =>
	import("./pages/Pages/Special/page-comingsoon2")
);
const PageError = React.lazy(() => import("./pages/Pages/Special/PageError"));
const PageMaintenance = React.lazy(() =>
	import("./pages/Pages/Special/PageMaintenance")
);
const Contact = React.lazy(() => import("./pages/Pages/Contact/contact"));
const PrivacyPolicy = React.lazy(() =>
	import("./pages/Pages/Utility/PagePrivacy")
);
const TermsNConditions = React.lazy(() =>
	import("./pages/Pages/Utility/PageTerms")
);
const AboutUs = React.lazy(() => import("./pages/Pages/PageAboutUs"));
const RefundPolicy = React.lazy(() =>
    import("./pages/Pages/Utility/PageRefundPolicy")
);

//User Pages
const Login = React.lazy(() => import("./pages/Pages/User/Login"));
const Register = React.lazy(() => import("./pages/Pages/User/Register"));
const Profile = React.lazy(() => import("./pages/Pages/User/Profile"));
const RecoveryPassword = React.lazy(() =>
	import("./pages/Pages/User/RecoveryPassword")
);

const CategoryListing = React.lazy(() =>
	import("./components/Shared/category-list")
);

//Class & Centre Pages
const CentreListing = React.lazy(() => import("./pages/Centre/centreListing"));
const VoucherDetails = React.lazy(() =>
	import("./pages/Voucher/voucherDetail")
);
const OwnerVoucherDetails = React.lazy(() =>
	import("./pages/Voucher/voucherOwnerDetails")
);
const ClassListing = React.lazy(() => import("./pages/Class/classListing"));
const ClassDetails = React.lazy(() => import("./pages/Class/classDetails"));
const CentreDetails = React.lazy(() => import("./pages/Centre/centreDetails"));

//Ebook & Video Pages
const EBookListing = React.lazy(() => import("./pages/eBook/eBookListing"));
const EBookDetails = React.lazy(() => import("./pages/eBook/eBookDetails"));
const VideoListing = React.lazy(() => import("./pages/Video/videoListing"));
const VideoDetails = React.lazy(() => import("./pages/Video/videoDetails"));

const SearchPage = React.lazy(() => import("./pages/GlobalSearch/index"));

const routes = [
	//Special Pages
	// { path: "/page-comingsoon", component: PageComingSoon },
	// { path: "/contact", component: Contact },
	// { path: "/privacy-policy", component: PrivacyPolicy },
	// { path: "/terms-and-conditions", component: TermsNConditions },
	// { path: "/about-us", component: AboutUs },
    // { path: "/refund-policy", component: RefundPolicy },

	//User Pages
	{ path: "/login", component: Login },
	{ path: "/register", component: Register },
	{ path: "/profile", component: Profile },
	{ path: "/forgot-password", component: RecoveryPassword },

	//Class & Centre & Voucher
	// { path: "/centre/:id", component: CentreDetails },
	// { path: "/centre-list/:id", component: CentreListing },
	// { path: "/centre-list", component: CentreListing },
	// { path: "/voucher/:id", component: VoucherDetails },
	// { path: "/owner-voucher/:id", component: OwnerVoucherDetails }, // another component like create form
	// { path: "/claimed-voucher/:id", component: VoucherDetails },
	//    { path: '/class-list/:id', component: ClassListing },
	//     { path: '/class-list', component: ClassListing },
	// { path: "/course/:id", component: ClassDetails },

	//eBook & Video
	// { path: "/ebook/:id", component: EBookDetails },
	// { path: "/ebook-list/:id", component: EBookListing },
	// { path: "/ebook-list", component: EBookListing },
	// { path: "/video/:id", component: VideoDetails },
	// { path: "/video-list/:id", component: VideoListing },
	// { path: "/video-list/", component: VideoListing },

	// Shared Listing
	// { path: "/category-list/centre", component: CategoryListing },
	// { path: "/category-list/class", component: CategoryListing },
	// { path: "/search", component: SearchPage },

	//Root
	{ path: "/", component: Root },
];

export default routes;
