import React, { Component, Suspense } from "react";
import { withRouter } from "react-router-dom";
// Scroll up button
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";

import * as CENTRE from "../../services/centre";
import * as CLASS from "../../services/class";

// Layout Components
const Topbar = React.lazy(() => import("./Topbar"));
const NavbarPage = React.lazy(() =>
	import("../../pages/Saas Onepage/NavbarPage")
);
const Footer = React.lazy(() => import("./Footer"));

class Layout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			centreCat: [],
			classCat: [],
		};
	}

	async componentDidMount() {
		CENTRE.getCategories({
			per_page: 999,
			sort_type: "asc",
			sort_by: "time",
		}).then((centreRes) => {
			if (centreRes.data) this.setState({ centreCat: centreRes.data.data });
		});
		CLASS.getCategories({
			per_page: 999,
			sort_type: "asc",
			sort_by: "time",
		}).then((classRes) => {
			if (classRes.data) this.setState({ classCat: classRes.data.data });
		});
	}

	Loader = () => {
		return (
			<div id="preloader">
				<div id="status">
					<div className="spinner">
						<div className="double-bounce1"></div>
						<div className="double-bounce2"></div>
					</div>
				</div>
			</div>
		);
	};

	render() {
		const { centreCat, classCat } = this.state;
		return (
			<React.Fragment>
				<Suspense fallback={this.Loader()}>
					<Topbar centreCat={centreCat} classCat={classCat} />

					{this.props.children}
					{(() => {
						if (
							this.props.location.pathname === "/index-marketing" ||
							this.props.location.pathname === "/index-modern-business" ||
							this.props.location.pathname === "/index-services" ||
							this.props.location.pathname === "/index-job"
						) {
							return <Footer isLight={true} />;
						} else if (
							this.props.location.pathname === "/index-portfolio" ||
							this.props.location.pathname === "/page-contact-two"
						) {
							return <Footer isLight={true} />;
						} else if (
							this.props.location.pathname === "/index-personal" ||
							this.props.location.pathname === "/page-invoice"
						) {
							return <Footer isLight={true} />;
						} else {
							return <Footer navigation={this.props.history} />;
						}
					})()}
					{/* <ThemeSwitcher/> */}
					<div id="bottomIcon">
						{/* scrollup button */}
						<ScrollUpButton className="bottomIcon" />
					</div>
				</Suspense>
			</React.Fragment>
		);
	}
}

export default withRouter(Layout);
