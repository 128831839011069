import { combineReducers } from 'redux';

const updateProfileReducer = (state = false, action) => {
    switch (action.type) {
        case 'UPDATE_PROFILE':
            return { ...state, profile: true };
        case 'FINISH_UPDATE':
            return { ...state, profile: false };
        default:
            return state;
    }
};

const rootReducer = combineReducers({
    data: updateProfileReducer,
});

export default rootReducer;