import React, { Component, Suspense } from "react";
import Layout from "./components/Layout/";
import { Redirect, Route, Switch, Router, withRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ToastContainer } from "react-toastify";
import { ScrollContext } from "react-router-scroll-4";
import Firebase, { FirebaseContext } from "./services/firebase";

// Import Css
import "react-toastify/dist/ReactToastify.min.css";
import "./Apps.scss";
import "./css/materialdesignicons.min.css";
import "./css/colors/default.css";

// Include Routes
import routes from "./routes";
const hist = createBrowserHistory();
const $ = require("jquery");

// function withLayout(WrappedComponent) {
//   return class extends React.Component {
//     render() {
//       return <Layout>
//         <WrappedComponent history={this.props.history}></WrappedComponent>
//       </Layout>
//     }
//   };
// }

class App extends Component {
	Loader = () => {
		return (
			<div id="preloader">
				<div id="status">
					<div className="spinner">
						<div className="double-bounce1"></div>
						<div className="double-bounce2"></div>
					</div>
				</div>
			</div>
		);
	};
	render() {
		return (
			<FirebaseContext.Provider value={new Firebase()}>
				<React.Fragment>
					<ToastContainer />
					<Router basename={"/"} history={hist}>
						<ScrollContext>
							<Layout>
								<Suspense fallback={this.Loader()}>
									<Switch>
										{routes.map((route, idx) => (
											<Route
												path={route.path}
												exact={route.path == "/"}
												component={route.component}
												key={idx}
											/>
										))}
										<Redirect to="/" />
									</Switch>
								</Suspense>
							</Layout>
						</ScrollContext>
					</Router>
				</React.Fragment>
			</FirebaseContext.Provider>
		);
	}
}

export default withRouter(App);
