import * as GENERAL from './general'
const axios = require("axios");

export async function getCategories(params) {
  let url=process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX +'/ml-class-category?'
  if(params.per_page)  
    url+="&per_page="+params.per_page
  if(params.page)
    url+="&page="+params.page
  if(params.is_feature)
    url+="&is_feature="+params.is_feature
  if(params.sort_by)  
    url+="&sort_by="+params.sort_by
  if(params.sort_type)
    url+="&sort_type="+params.sort_type
    return new Promise((resolve, reject) => {
    axios
      .get(url,{
        headers: GENERAL.returnHeader()
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
      })
  });
}

export async function getClassList(params) {
  let url=process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX +'/ml-class?'
  if(params.per_page)  
    url+="&per_page="+params.per_page
  if(params.page)
    url+="&page="+params.page
  if(params.categories)
    url+="&categories="+params.categories
  if(params.search)
    url+="&search="+params.search
  if(params.sort_by)  
    url+="&sort_by="+params.sort_by
  if(params.sort_type)
    url+="&sort_type="+params.sort_type
    return new Promise((resolve, reject) => {
    axios
      .get(url,{
        headers: GENERAL.returnHeader()
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
      })
  });
}

export async function getClassDetails(id) {
  let url=process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX +'/ml-class/'+id

    return new Promise((resolve, reject) => {
    axios
      .get(url,{
        headers: GENERAL.returnHeader()
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
      })
  });
}